
import Web3 from "web3";
import React, { useEffect, useState } from "react";
import Web3Modal from "web3modal";
import ReactLoading from 'react-loading';
import { Buffer } from "buffer";
import { nft_contract_address, nft_contract_abi,chain_id, speedy_nodes, fetch_data_contract_address, fetch_data_contract_abi} from './config';
import {zues1_ids,zues2_ids,zues3_ids,zues4_ids,zues5_ids,zues6_ids,zues7_ids,zues8_ids} from './config';
const Main = () => {

  const [processOne, setProcessOne] = useState(false);
  const [processTwo, setProcessTwo] = useState(false);
  const [firstHalf, setFirstHalf] = useState('');
  const [secondHalf, setSecondHalf] = useState('');
  const [total_Nfts_in_Walelt, settotal_Nfts_in_Walelt] = useState(1);
  const [totalPoints, settotalPoints] = useState("0");
  const [totalBonus, settotalBonus] = useState("0");

  const [noNfts, setNoNfts] = useState(false);

  //const totalPoints = 1280;
  let connectWalletPressed = 0;
  const [isWalletConnected,setisWalletConnected]= useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [connectedAddress, setconnectedAddress] = useState("");

  useEffect(() => {

    //fetch_data_before();
    connect_wallet();   
    //fetch_Data("0xc86C198F9373b3EbCc6a825B9caC6603D2ca24c9");
}, [])

  const values = [ 
    '0xc86C198F9373b3EbCc6a825B9caC6603D2ca24c9'
  ];
  const [images, setImages] = useState([]);
  // const images = [
  //   {
  //     no: 3855,
  //     link: '/images/1.gif',
  //     points: 12120
  //   }
  // ];

  async function connect_wallet(){

    if(Web3.givenProvider){
      const providerOptions = {
        /* See Provider Options Section */
      };
  
      const web3Modal = new Web3Modal({
        cacheProvider: true, // optional
        providerOptions // required
      });
      
      const provider = await web3Modal.connect();
      provider.on("accountsChanged", (accounts) => {
        console.log(accounts);
        window.location.reload();
      });
      
      // Subscribe to chainId change
      provider.on("chainChanged", (chainId) => {
        console.log(chainId);
        window.location.reload();
      });
      
      // Subscribe to provider connection
      provider.on("connect", (info) => {
        console.log(info);
        window.location.reload();
      });
      
      // Subscribe to provider disconnection
      provider.on("disconnect", (error) => {
        console.log(error);
        window.location.reload();
      });
      
      const web3 = new Web3(provider);
      const addresses = await web3.eth.getAccounts()
       let address = addresses[0];
       //address = "0xE436c5c45D5B8b9DeDF37A59d9BE8AE7864b26CB";
      //  setWeb3Global(web3);
      // setConnectedAddress(address);
      // setShortenAddress(address.slice(0, 4) + "..." + address.slice(address.length - 3, address.length))
      //alert("Connected")
      web3.eth.net.getId().then((result) => { 
    
      console.log("Network id: "+result)
      if(result !== parseInt(chain_id)){ //result !== 1
          //alert("Wrong Network Selected. Select Goerli Test Network and Refresh Page");
          try{
            window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: web3.utils.toHex(chain_id) }]
            });
          }catch(e){
            alert("Error in Switching Chain, Make Sure you have added cronos chain in your web3 wallet.");
            window.location.reload();
          }
  
        }else{
        //   const contract = new web3.eth.Contract(contract_abi, contract_address);
        setFirstHalf(address.substr(0, 6));
        setSecondHalf(address.substr(address.length - 5));
        setisWalletConnected(true);
        setconnectedAddress(address);
        setProcessOne(true);
        // setStateOne(!stateOne);
        // fetch_Data_After_Wallet_Connect(address);
        // setShowPopup(false);
            // get_nfts(address);
            // get_data(web3, address)
        }
      })
  
    }else{
      if(connectWalletPressed >= 3){
        alert("Web3 Wallet Not Found. Open in Metamask Built-in Browser.");
      }
      
      connectWalletPressed++;
    }
    
  }
  function  get_image_url(id){
    let i = parseInt(id);
    let img_url = "";
    let encode = Buffer.from(id.toString()).toString('base64');
    if(i <= 1000){
    img_url = "https://magenta-binding-mastodon-743.mypinata.cloud/ipfs/QmXXqHac7jjUqRAZ5xepuQ2VLWYzGSCD2LKDU7DtiCuh7o/"+encode+".png";
   
}
else if(i >= 1001 && i <= 2000){
    img_url = "https://magenta-binding-mastodon-743.mypinata.cloud/ipfs/QmPZLuiqDng6hFVavDkjmCZLZbTPT3wM7x5wAAgGC6ronz/"+encode+".png";

}
else if(i >= 2001 && i <= 3000){
    img_url = "https://magenta-binding-mastodon-743.mypinata.cloud/ipfs/QmV4MG9xT3G9LXRFoRfd1CL9eD3oByRRskFWCsWGuQqnzM/"+encode+".png";

}
else if(i >= 3001 && i <= 4000){
    img_url = "https://magenta-binding-mastodon-743.mypinata.cloud/ipfs/QmZ3yViwYGBcvSuk6cwuhYuaTPE4oVD2oe84iRRWkRSYQi/"+encode+".png";

}
else if(i >= 4001 && i <= 5000){
    img_url = "https://magenta-binding-mastodon-743.mypinata.cloud/ipfs/QmayzQ5UvEvFsXaLh6w3itynBfhvgfXFam6GDokydQFeLn/"+encode+".png";

}
else if(i >= 5001 && i <= 6000){
    img_url = "https://magenta-binding-mastodon-743.mypinata.cloud/ipfs/QmWoNZ4dM1zfbkF1fyBbzSKKRbwJmAyQ4U3LeDpmafeNGG/"+encode+".png";
 
}
else if(i >= 6001 && i <= 7000){
    img_url = "https://magenta-binding-mastodon-743.mypinata.cloud/ipfs/QmcGqwPAejZWXFif7AJEsKVhDjzw5BiUcgZvtXUsgceS3H/"+encode+".png";

}
else if(i >= 7001 && i <= 8000){
    img_url = "https://magenta-binding-mastodon-743.mypinata.cloud/ipfs/QmRBjzXXZvMWRRxU22iHjVYiipX4Gn6EW2rJ6aWHHpNmvZ/"+encode+".png";

}
else if(i >= 8001 && i <= 9000){
    img_url = "https://magenta-binding-mastodon-743.mypinata.cloud/ipfs/QmW1PL9GF3PgxtUh6JDmF1ECrc7q3DcrmXPafsfcHxce7s/"+encode+".png";

}
else if(i >= 9001 && i <= 10000){
    img_url = "https://magenta-binding-mastodon-743.mypinata.cloud/ipfs/QmaKxzcpuC4ruftrnwkT3mWciSbUwHuk9XjR5WpjhT7dyU/"+encode+".png";

}
else if(i >= 10001 && i <= 11111){
    img_url = "https://magenta-binding-mastodon-743.mypinata.cloud/ipfs/Qmf3t6YEzVLQJUeB6Byn7BSeEGN3VqpqMSEe2aijH1ysbQ/"+encode+".png";

}
//console.log(img_url)
return img_url;
}
  function calculate_reward_for_id(id){
    let current_id = parseInt(id);
    let total_rewards = 0;
    if(zues1_ids.includes(current_id)){
      total_rewards =   55555;
    }else if(zues2_ids.includes(current_id)){
      total_rewards =   77777;
    }
    else if(zues3_ids.includes(current_id)){
      total_rewards =   111111;
    }
    else if(zues4_ids.includes(current_id)){
      total_rewards =   222222;
    }
    else if(zues5_ids.includes(current_id)){
      total_rewards =   333333;
    }
    else if(zues6_ids.includes(current_id)){
      total_rewards =   555555;
    }
    else if(zues7_ids.includes(current_id)){
      total_rewards =   777777;
    }
    else if(zues8_ids.includes(current_id)){
      total_rewards =   1111111;
    }else{
      total_rewards =   33333;
    }

    return total_rewards;
  }
  
  async function fetch_Data(connectedAddress){
    setFirstHalf(connectedAddress.substr(0, 6));
    setSecondHalf(connectedAddress.substr(connectedAddress.length - 5));

    const web3 = new Web3(speedy_nodes);
    const fetch_ids_contract = new web3.eth.Contract(fetch_data_contract_abi, fetch_data_contract_address);
    //await Web3.givenProvider.enable()
    
    let idsinWallet = [];
    let all_images = [];

    await fetch_ids_contract.methods.getNftsIdsbyAddress(nft_contract_address, connectedAddress).call((err,result) => {
      console.log("error: "+err);
      if(result != null){
        idsinWallet = result;

      }
  })
  console.log("idsinWallet: "+ idsinWallet);
  if(idsinWallet.length == 0){
    setNoNfts(true);
    return;
  }
  let total_rewards = 0; 

  for(let i=0;i<idsinWallet.length;i++){
    let current_id = parseInt(idsinWallet[i]);
    let currect_reward = calculate_reward_for_id(current_id);
    //Calculate total Points
    total_rewards = total_rewards + currect_reward;

    all_images.push({
      no: current_id,
      link: get_image_url(current_id),
      points: currect_reward      
    })

  }

  if(idsinWallet.length >= 5 && idsinWallet.length < 10){
    
    let numFor = Intl.NumberFormat("en-US");
    settotalBonus(numFor.format(33333));
    total_rewards =  total_rewards + 33333;
  }
  if(idsinWallet.length >= 10){
    let numFor = Intl.NumberFormat("en-US");
    settotalBonus(numFor.format(77777));
    total_rewards =  total_rewards + 77777;
  }

console.log("total_rewards: " + total_rewards)
settotal_Nfts_in_Walelt(idsinWallet.length);

let numFor = Intl.NumberFormat("en-US");

settotalPoints(numFor.format(total_rewards))
setImages(all_images);
setProcessOne(true)
setProcessTwo(true)

  }
  const formAction = e => {
    e.preventDefault();
    let value = document.getElementById('address').value;
    setFirstHalf(value.substr(0, 6));
    setSecondHalf(value.substr(value.length - 5));
    setconnectedAddress(value)
    setProcessOne(true);
    
  }

  const fetchAction = e => {
    e.preventDefault();
    setProcessTwo(true);
  }

  return (
    <>
      <div className="main" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'images/bg.jpg'})` }}>
        <div className="container">

          <div className="logo">
            <h1>SPARKTANS</h1>
          </div>

          <ul className="social-list">
            <li className="icon">
              <a
                href="https://twitter.com/sparkcoin_io"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  color="#2208b8"
                  height="30"
                  width="30"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                </svg>
              </a>
            </li>
            <li className="icon">
              <a
                href="https://discord.gg/XfURXdTzMS"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 640 512"
                  color="#2208b8"
                  height="30"
                  width="30"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path>
                </svg>
              </a>
            </li>
          </ul>

          <div className="main-form">
            <form>
              <input id="address" name="address" placeholder="Input SGB address" />
              <input onClick={formAction} type="submit" />
            </form>
          </div>
          
          {processOne ? (
            <div className="info">
              <p className="string">
                {`${firstHalf}...${secondHalf}`}
              </p>
              <button className="sc-iqHYmW kXtkAP" onClick={() => {
                fetch_Data(connectedAddress);
              }}>FETCH NFTs</button>
              {!processTwo ? (
                <div className="info-inner">
                  {noNfts && (
                    <p className="string">YOU DONT HAVE ANY SPARKTANS!</p>
                  )}
                  
                  <a href="https://mint.sparkcoin.io/" target="_blank" className="mint-link" rel="noopener noreferrer">Mint Now</a>
                </div>
              ) : (
                <div className="points">
                  <a target="_blank" href="https://mint.sparkcoin.io/" className="mint-link" rel="noopener noreferrer">MINT</a>
                  <p className="string">
                    YOUR TOTAL SPARKTANS!
                    <strong className="green-big">
                    {`${total_Nfts_in_Walelt}`}
                    </strong>
                  </p>
                  <p className="string">
                    BONUS (AGOGE or PHALANX)
                    <strong className="green-big">
                      {`${totalBonus}`}
                      {/* 77,777 */}
                    </strong>
                  </p>
                  <div className="conterdiv">
                  <p className="string">
                    YOUR TOTAL SPARKTAN POINTS INCLUDING ABOVE BONUS
                    <strong className="green-big">
                      {/* 6,499,948 */}
                      {`${totalPoints}`}
                    </strong>
                  </p>
                  {/* <br /> */}
                  <p className="string">
                    &#9888; Please keep in mind that the Bonus only applies to wallets of up to 10 Sparktans. We
                    highly recommend creating multiple wallets for holders who have more than 10
                    Sparktans that want to claim additional AGOGE or PHALANX &#9888;
                  </p>
                  </div>

                </div>
              )}
            </div>
          ) : (
            <p className="note">PLEASE ENTER A VALID SGB ADDRESS</p>
          )}
          <div className="points">
                  <div className="row">
                    {images.map(({no, link, points}) => {
                      return (
                        <div className="col" key={no}>
                          <p className="string">Sparktan #{no}</p>
                          <p className="string">Points: {points}</p>
                          <div className="main-gif">
                            <div className="main-gif-inner">
                              <div className="inner">
                                <img className="nftimg" src={link} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  </div>
        {!isWalletConnected && (
          <button className="btn" onClick={connect_wallet} type='button'>Connect Wallet</button>
        )}
          
        </div>
      </div>
    </>
  );
};

export default Main;
