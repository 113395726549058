import './App.css';
import Main from './Main';

function App() {
  return (
    <>
      <Main />
    </>
  );
}

export default App;
